/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import MediaQuery from 'react-responsive'

// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import SoftTypography from "../../../components/SoftTypography";
import SoftButton from "../../../components/SoftButton";
import SoftSelect from "components/SoftSelect";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import breakpoints from "assets/theme/base/breakpoints";

// import 'react-tabs/style/react-tabs.css';
// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Pricing page components
import Footer from "layouts/pages/home/components/Footer";
import AdminFooter from "examples/Footer";

import Grid from "@mui/material/Grid";

import WorkingHours from '@mui/icons-material/AlarmOn';
import InfoIcon from '@mui/icons-material/Info';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import RateReviewIcon from '@mui/icons-material/RateReview';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

// Helpers
import BackRequests from "helpers/back-request/back-requests.enum";
import {ErrorMessageSnackBar} from "helpers/messages/helpers";
import {CreateGetRequest, CreatePostRequest, GetErrorMessage} from "helpers/back-request/helpers"
import SoftAlert from "components/SoftAlert";

//Modal import
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import FormField from "components/common/FormField";

// reservation schemas for form and form feilds
import useValidations from "layouts/pages/home/schemas/validations";
import useFormFields from "layouts/pages/home/schemas/form";
import useInitialValues from "layouts/pages/home/schemas/initialValues";
import FormNames from "layouts/pages/home/schemas/form-names.enum";

import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import AdditionalInformationTab from "./event-tabs/additional-information-tab";
import PageHeader from "./components/PageHeader";
import ProgramsAndScheduleTab from "./event-tabs/programs-and-schedule-tab";
import PricelistTab from "./event-tabs/pricelist-tab";
import PricelistTabMob from "./event-tabs/pricelist-tab-mob";

import MediaTab from "./event-tabs/media-tab";
import ReviewsTab from "./event-tabs/reviews-tab";
import MainInformationTab from "./event-tabs/main-information-tab";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { CircularProgress } from "@mui/material";


function EventPage(props) {
    const location = useLocation();
    const data = location.state;
    const { t, i18n } = useTranslation();
    const [serviceModalOptions, setServiceModalOptions] = useState([]);
    const [reviewsAndRating, setReviewsAndRating] = useState([]);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const businessItemId = Number(data.id);
    const { initial} = useInitialValues();
    const formValidation = useValidations()[FormNames.MAKE_RESERVATION];
    const formFields = useFormFields(t)[FormNames.MAKE_RESERVATION];
    const initialValues = initial[FormNames.MAKE_RESERVATION];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 1,
        borderRadius: '30px',
        //border: "5px solid red",
        "@media (max-width: 430px)": {
            width: '80%',
        },
        "@media (min-width: 431px)": {
            width: '40%',
        }
    };


    const successMessageRef = useRef();
    const [isFinished, setIsFinished] = useState(false);
    const [agreement, setAgreement] = useState(true);

    const handleSetAgreement = () => setAgreement(!agreement);

    const handleSubmit = async (values, actions) => {
        console.log(values)
        try {
            await CreatePostRequest(BackRequests.CREATE_RESERVATION,
                {
                    userId: JSON.parse(localStorage.getItem("userId")) ?? null,
                    businessItemId,
                    chosenItemId: values.service.value,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                });
            handleClose();
            setIsFinished(true)
        } catch (error) {
            openErrorSB(GetErrorMessage(error));
        }
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [businessItemData, setBusinessItemData] = useState({});

    // Error messages
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSB, setErrorSB] = useState(false);
    const openErrorSB = (message) => {
        setErrorMessage(message);
        setErrorSB(true);
    }
    const closeErrorSB = () => setErrorSB(false);

    const getBusinessItemDetails = async () => {
        try {
            const response = await CreateGetRequest(BackRequests.GET_BUSINESS_ITEM_BY_ID + businessItemId);
            const responseData = response.data.data;

            // Populaye services for modal
            const options = responseData.pricelist.map((priceListItem) => {
                return {
                    value: priceListItem.id,
                    label: priceListItem.description + " [" + priceListItem.discountedPrice + " " + priceListItem.currency + "]"
                }
            })
            setServiceModalOptions(options);

            let mainImage = null;
            if (responseData.mediaPayload?.mainImage) {
                const mainImageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + responseData.mediaPayload?.mainImage, { responseType: 'blob' });
                mainImage = URL.createObjectURL(mainImageResponse.data);
            }

            let coverImage = null;
            if (responseData.mediaPayload?.coverImage) {
                const coverImageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + responseData.mediaPayload?.coverImage, { responseType: 'blob' });
                coverImage = URL.createObjectURL(coverImageResponse.data);
            }

            let galleryImages = [];
            if (responseData.mediaPayload?.gallery.length > 0) {
                for (let i = 0; i < responseData.mediaPayload?.gallery.length; i++) {
                    const galleryImageId = responseData.mediaPayload?.gallery[i];
                    const galleryImagesResponse = await CreateGetRequest(BackRequests.GET_MEDIA + galleryImageId, { responseType: 'blob' });
                    galleryImages.push(URL.createObjectURL(galleryImagesResponse.data))
                }
            }

            const { mediaPayload, ...otherProperties } = responseData;
            return {
                ...otherProperties,
                mediaPayload: {
                    mainImage,
                    coverImage,
                    galleryImages
                }
            }
        } catch (error) {
            console.log("error")
            console.log(error)
            // TODO: Sta na stranici prikazati ako se ne ucitaju podaci
            openErrorSB(GetErrorMessage(error), true);
        }
    };

    const getReviewsAndRating = async () => {
        try {
            const response = await CreatePostRequest(BackRequests.GET_BUSINESS_ITEM_REVIEWS, {
                businessItemId
            });
            const reviewsData = response.data.data;
            if (reviewsData.reviews.length > 0) {
                for (let i = 0; i < reviewsData.reviews.length; i++) {
                    const userImageId = reviewsData.reviews[i].user.profileImage;
                    if (userImageId == null) {
                        continue
                    }
                    const userImagesResponse = await CreateGetRequest(BackRequests.GET_MEDIA + userImageId, { responseType: 'blob' });
                    reviewsData.reviews[i].user.profileImageContent = URL.createObjectURL(userImagesResponse.data);
                }
            }
            return reviewsData
        } catch (error) {
            console.log("error")
            console.log(error)
            // TODO: Sta na stranici prikazati ako se ne ucitaju podaci
            openErrorSB(GetErrorMessage(error), true);
        }
    };

    const refreshReviews = async () => {
        const reviewsAndRating = await getReviewsAndRating();
        setReviewsAndRating(reviewsAndRating);
    };

    useEffect(() => {
        const loadData = async () => {
            const reviewsAndRating = await getReviewsAndRating();
            console.log(reviewsAndRating);
            setReviewsAndRating(reviewsAndRating);
        }
        loadData();
        setUserLoggedIn(localStorage.getItem("user") ? true : false);
    }, []);


    useEffect(() => {
        const loadData = async () => {
            const businessItemData = await getBusinessItemDetails();
            console.log(businessItemData);
            setBusinessItemData(businessItemData);
            successMessageRef.current?.scrollIntoView(
                {
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'end'
                })
        }
        loadData();
    }, [isFinished]);

    const [value, setValue] = React.useState('main-information-tab');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [tabsOrientation, setTabsOrientation] = useState("horizontal");

    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }

        /** 
         The event listener that's calling the handleTabsOrientation function when resizing the window.
        */
        window.addEventListener("resize", handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);


    const getBusienssElements = () => {
        return (
            <SoftBox>
                <SoftBox xs={12} md={12} lg={12} align="center">
                    <PageHeader
                        businessName={businessItemData.name}
                        businessRating={reviewsAndRating?.rating}
                        businessRatingCount={reviewsAndRating?.ratingCount}
                        businessCoverImage={businessItemData.mediaPayload?.coverImage}
                        businessMainImage={businessItemData.mediaPayload?.mainImage}
                        isAdmin={data.isAdmin} 
                        businessType={businessItemData.type}
                        coachBirth={businessItemData.personalCoachPayload?.yearOfBirth}
                        socialAccountsFacebook={businessItemData.socialAccounts?.facebookAccount}
                        socialAccountsInstagram={businessItemData.socialAccounts?.InstagramAccount}
                        socialAccountsX={businessItemData.socialAccounts?.TwitterAccount}
                        />
                </SoftBox>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <SoftBox p={3} mb={1} textAlign="center">
                            <SoftTypography variant="h5" fontWeight="medium">
                                Zahtev za rezervacijom
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox pt={2} pb={3} px={3}>
                            <Formik
                                enableReinitialize
                                initialValues={initialValues}
                                validationSchema={formValidation}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                                    <Form id={FormNames.MAKE_RESERVATION} autoComplete="off">
                                        <SoftBox xs={12} md={12} lg={12}>
                                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    {formFields.service.label}
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftSelect
                                                name={formFields.service.name}
                                                label={formFields.service.label}
                                                options={serviceModalOptions}
                                                value={values.service}
                                                onChange={(event) => {
                                                    setFieldValue(formFields.service.name, event);
                                                }}
                                                error={(errors.service && touched.service) ? true : false}
                                                disabled={isSubmitting}
                                            />
                                            <SoftBox mb={2}>
                                                <FormField
                                                    type={formFields.firstName.type}
                                                    label={formFields.firstName.label}
                                                    name={formFields.firstName.name}
                                                    value={values.firstName}
                                                    placeholder={formFields.firstName.placeholder}
                                                    error={errors.firstName && touched.firstName}
                                                    disabled={userLoggedIn}
                                                />
                                            </SoftBox>
                                            <SoftBox mb={2}>
                                                <FormField
                                                    type={formFields.lastName.type}
                                                    label={formFields.lastName.label}
                                                    name={formFields.lastName.name}
                                                    value={values.lastName}
                                                    placeholder={formFields.lastName.placeholder}
                                                    error={errors.lastName && touched.lastName}
                                                    disabled={userLoggedIn}
                                                />
                                            </SoftBox>
                                            <SoftBox mb={2}>
                                                <FormField
                                                    type={formFields.email.type}
                                                    label={formFields.email.label}
                                                    name={formFields.email.name}
                                                    value={values.email}
                                                    placeholder={formFields.email.placeholder}
                                                    error={errors.email && touched.email}
                                                    disabled={userLoggedIn}
                                                />
                                            </SoftBox>
                                            {!isSubmitting ? 
                                                <SoftBox display="flex" justifyContent="center">
                                                    <SoftBox mr={2}>
                                                        <SoftButton color="light" onClick={handleClose} disabled={isSubmitting}>
                                                            Odustani
                                                        </SoftButton>
                                                    </SoftBox>
                                                    <SoftButton color="secondary" type="submit" disabled={isSubmitting}>
                                                        Posalji zahtev
                                                    </SoftButton>
                                                </SoftBox> : 
                                                <SoftBox align="center" mt={3}>
                                                    <CircularProgress color="secondary" width="50%"/> 
                                                </SoftBox> }
                                        </SoftBox>
                                    </Form>
                                )}
                            </Formik>
                        </SoftBox>
                    </Box>
                </Modal>
                <Container>
                    <SoftBox xs={12} md={8} lg={8}>
                        <SoftBox py={3} >
                            <Card>
                                <SoftBox p={1}>
                                    <TabContext value={value}>
                                        <Tabs onChange={handleChange} value={value} orientation={tabsOrientation} variant="scrollable">
                                            <Tab label={t('tabs.tab_about')} icon={<InfoIcon color="primary" />} value="main-information-tab" />
                                            <Tab label={t('tabs.tab_aboutMore')} icon={<DataSaverOnIcon color="primary" />} value="additional-information-tab" />
                                            <Tab label={t('tabs.tab_programs')} icon={<WorkingHours color="primary" />} value="programs-and-schedule-tab" />
                                            <Tab label={t('tabs.tab_price')} icon={<MonetizationOnIcon color="primary" />} value="pricelist-tab" />
                                            <Tab label={t('tabs.tab_media')} icon={<PermMediaIcon color="primary" />} value="media-tab" />
                                            <Tab label={t('tabs.tab_review')} icon={<RateReviewIcon color="primary" />} value="reviews-tab" />
                                        </Tabs>
                                        <TabPanel value="main-information-tab">
                                            <MainInformationTab businessType={businessItemData.type} businessDescription={businessItemData.description} personalCoachPayload={businessItemData.personalCoachPayload} personalCoachImage={businessItemData.mediaPayload?.mainImage} />
                                        </TabPanel>
                                        <TabPanel value="additional-information-tab">
                                            <AdditionalInformationTab addresses ={businessItemData.addresses} contactData={businessItemData.contactData} additional={businessItemData.additional} />
                                        </TabPanel>
                                        <TabPanel value="programs-and-schedule-tab">
                                            <ProgramsAndScheduleTab skillLevels={businessItemData.skillLevels} sports={businessItemData.sports} ageCategories={businessItemData.ageCategories} gender={businessItemData.gender} groupActivityPayload={businessItemData.groupActivityPayload} sportFacilityPayload={businessItemData.sportFacilityPayload} personalCoachPayload={businessItemData.personalCoachPayload} />
                                        </TabPanel>
                                        <TabPanel value="pricelist-tab">
                                            <MediaQuery maxWidth={430}>
                                                <PricelistTabMob pricelist={businessItemData.pricelist} />
                                            </MediaQuery>
                                            <MediaQuery minWidth={431}>
                                                <PricelistTab pricelist={businessItemData.pricelist} />
                                            </MediaQuery>
                                        </TabPanel>
                                        <TabPanel value="media-tab">
                                            <MediaTab galleryImages={businessItemData.mediaPayload?.galleryImages} />
                                        </TabPanel>
                                        <TabPanel value="reviews-tab">
                                            <ReviewsTab reviews={reviewsAndRating.reviews} rating={reviewsAndRating.rating} ratingCount={reviewsAndRating.ratingCount} businessItemId={businessItemId} refreshReviews={refreshReviews} />
                                        </TabPanel>
                                    </TabContext>
                                </SoftBox>
                                <SoftBox p={4}>
                                    <Grid item xs={12} lg={12} xl={12} px={1} py={3} align="right">
                                        <SoftButton color="secondary" onClick={handleOpen}>
                                            Move ON
                                        </SoftButton>
                                    </Grid>
                                </SoftBox>
                            </Card>
                        </SoftBox>
                        {isFinished && 
                            <SoftBox width="100%" align="center">
                                <SoftAlert color="success" width="50%" dismissible>
                                    <SoftTypography variant="body2" color="white">
                                        You have successfully sent booking request. Please continue process by checking the email
                                        for additional information.
                                    </SoftTypography>
                                </SoftAlert>
                                <SoftBox ref={successMessageRef}></SoftBox>
                            </SoftBox>
                        }
                        {ErrorMessageSnackBar({ title: "Booking request error", errorMessage, errorSB, closeErrorSB })}
                    </SoftBox>
                </Container>
            </SoftBox>
        )
    };

    if (location.state.isAdmin) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                {getBusienssElements()}
                <AdminFooter />
            </DashboardLayout>
        )
    } else {
        return (
            <PageLayout>
                {getBusienssElements()}
                <Footer />
            </PageLayout>
        )
    }
}

export default EventPage;
